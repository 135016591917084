import React, { useRef } from 'react';
import { Alert, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import CoreProfessionSelect from '../Form/CoreProfessionSelect/CoreProfessionSelect';
import CountrySelect from '../Form/CountrySelect';
import EMDRLevelSelect from '../Form/EMDRLevelSelect/EMDRLevelSelect';
import MembershipTypeSelect from '../Form/MembershipTypeSelect/MembershipTypeSelect';
import ProfessionalRegistrationSelect from '../Form/ProfessionalRegistrationSelect';
import RegionSelect from '../Form/RegionSelect/RegionSelect';
import TitleSelect from '../Form/TitleSelect';
import UsernameInput from '../Form/UsernameInput/UsernameInput';
import ChildLevelSelect from './ChildLevelSelect';
import { IMembershipJoinForm } from './IMembershipJoinForm';
import { useMembershipJoinFormComponent } from './useMembershipJoinFormComponent';

import imgSrc from '../../logo.png';

import FormStepper from './FormStepper';
import './index.scss';
import { MembershipCodeForm } from '../MembershipPayment/MembershipCodeForm';
import LanguagesSelect from '../Form/LanguagesSelect/LanguagesSelect';

const MembershipJoinForm: React.FC<IMembershipJoinForm> = ({ nonUkResident, onSubmit }) => {

    const formRef = useRef(null);

    const { models, operations } = useMembershipJoinFormComponent({ nonUkResident, onSubmit });
    const { handleInputChange, handleInputChangeEvent, handleCheckboxChangeEvent, handleNextStep, handleCancel, handleSubmit } = operations;
    const { formData, emdrTrainingLevels, validated, currentStep, showAddressInfo, showProfessionInfo, showMembershipInfo, showComplete, submitError, submitInProgress } = models;

    return (
        <Card style={{ maxWidth: '520px' }} border="light" className="membership-join-form mx-auto" ><Card.Body>
            <Row>
                <Col xs={12} className="text-center my-4">
                    <img src={imgSrc} width="100"></img>
                </Col>
            </Row>
            {submitError &&
                <Alert variant="danger" dismissible={true}>
                    There was an error submitting your membership, please try again.
                </Alert>}


            <Form ref={formRef} onSubmit={(e) => operations.handleSubmit(e)} style={{ textAlign: 'left' }} noValidate validated={validated}>
                <h3 data-testid="membership-heading" className="text-primary text-center my-4">
                    {showComplete ? <b> Sign up complete<i className="bi bi-check2 ms-2"></i> </b> : <b>Join the EMDR Association</b>}
                </h3>


                <FormStepper
                    formRef={formRef}
                    step={currentStep}
                    handleCancel={handleCancel}
                    handleNextStep={handleNextStep}
                    handleSubmit={handleSubmit}
                    allowSubmit={showMembershipInfo}
                    submitting={submitInProgress}
                    submitted={showComplete}>
                    <>
                        <Collapse in={showAddressInfo} dimension="height">
                            {renderContactDetailsFormSection()}
                        </Collapse>

                        <Collapse in={showProfessionInfo} dimension="height">
                            {renderQualificationFormSection()}
                        </Collapse>

                        <Collapse in={showMembershipInfo} dimension="height">
                            {renderLoginDetails()}
                        </Collapse>
                    </>
                </FormStepper>
            </Form >

        </Card.Body></Card>
    );


    function renderNameFormSection() {
        return <div>
            <Row>
                <Form.Group as={Col} lg={4} className="mb-3 fade-out" data-testid="membership-title">
                    {/*<FloatingLabel label="Title">*/}
                    <Form.Label>Title</Form.Label>

                    <TitleSelect
                        name="title"
                        value={formData.title}
                        onChange={handleInputChangeEvent} />
                    {/*    </FloatingLabel>*/}
                </Form.Group>

                <Form.Group as={Col} lg={8} className="mb-3" data-testid="membership-firstname">
                    {/*<FloatingLabel label="First Name">*/}
                    <Form.Label>Name</Form.Label>
                    <InputGroup>
                        <Form.Control
                            required
                            placeholder="First Name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChangeEvent} />
                        <Form.Control
                            required
                            placeholder="Last Name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChangeEvent} />
                    </InputGroup>

                    {/*    </FloatingLabel>*/}
                </Form.Group>

                {/*<Form.Group as={Col} lg={4} className="mb-3" data-testid="membership-lastname">*/}
                {/*    */}{/*<FloatingLabel label="Last Name">*/}
                {/*    <Form.Label>Last Name</Form.Label>*/}

                {/*    */}{/*</FloatingLabel>*/}
                {/*</Form.Group>*/}
            </Row>

        </div>;
    }

    function renderContactDetailsFormSection() {
        return <div>
            {renderNameFormSection()}
            <Row>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChangeEvent}
                        type="email" />
                </Form.Group>

                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-phone">
                    <Form.Label>Phone number</Form.Label>
                    <Form.Control
                        required
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
            </Row>
            <hr />
            <Row>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-address1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                        required
                        name="address1"
                        placeholder="Address Line 1"
                        value={formData.address1}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-address2">
                    <Form.Control
                        name="address2"
                        placeholder="Address Line 2"
                        value={formData.address2}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-city">
                    <Form.Control
                        required
                        name="city"
                        placeholder="Town / City"
                        value={formData.city}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
                <Form.Group as={Col} lg={4} className="mb-3" data-testid="membership-postcode">
                    <Form.Label>Postcode</Form.Label>
                    <Form.Control
                        required
                        name="postcode"
                        value={formData.postcode}
                        onChange={handleInputChangeEvent} />
                </Form.Group>

                <Form.Group as={Col} lg={8} className="mb-3" data-testid="membership-country">
                    <Form.Label>Country</Form.Label>
                    <CountrySelect
                        name="country"
                        value={formData.country}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" data-testid="membership-region">
                <Form.Label>Region</Form.Label>
                <RegionSelect
                    name="region"
                    value={formData.region}
                    onChange={handleInputChangeEvent} />
            </Form.Group>
        </div>;
    }

    function renderQualificationFormSection() {



        return <div>

            <Form.Group className="mb-3" data-testid="membership-professionalregistrations">
                <Form.Label>Professional registrations</Form.Label>
                <Form.Text className="mx-2">(Select all that apply)</Form.Text>

                <ProfessionalRegistrationSelect
                    name="registrations"
                    value={formData.registrations}
                    onChange={(value) => handleInputChange("registrations", value)} />

                {formData.registrations?.some(x => { return x.name?.startsWith("Other") }) && <Form.Text>If your professional registration is not listed, please contact <a href="mailto:info@emdrassociation.org.uk">info@emdrassociation.org.uk</a></Form.Text>}


            </Form.Group>

            <Form.Group className="mb-3" data-testid="membership-coreprofessions">
                <Form.Label>Core professions</Form.Label>
                <Form.Text className="mx-2">(Select all that apply)</Form.Text>

                <CoreProfessionSelect
                    name="professions"
                    value={formData.professions}
                    onChange={(value) => handleInputChange("professions", value)} />

                {formData.professions?.some(x => { return x.name?.startsWith("Other") }) && <Form.Text>If your core profession is not listed, please contact <a href="mailto:info@emdrassociation.org.uk">info@emdrassociation.org.uk</a></Form.Text>}

            </Form.Group>

            <Row>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-emdrlevel">
                    <Form.Label>EMDR Training Level</Form.Label>
                    <EMDRLevelSelect
                        name="emdrLevel"
                        value={formData.emdrLevel}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
                {formData.emdrLevel.length > 0 &&
                    <Col lg={12}>
                        <Form.Group className="py-1 pb-3 px-3 mb-3 bg-light" data-testid="membership-emdrleveldate">
                            <Form.Label aria-label="child training level date achieved">When did you complete your {emdrTrainingLevels.filter((s: any) => s.emdrTrainingLevelId === formData.emdrLevel)[0]?.name} training ?</Form.Label>
                            <Form.Control
                                required
                                name="emdrLevelDate"
                                value={formData.emdrLevelDate}
                                onChange={handleInputChangeEvent}
                                type="date"
                                placeholder="yyyy-mm-dd" />
                        </Form.Group>
                    </Col>
                }

            </Row>
            <Row>
                <Form.Group as={Col} lg={12} className="mb-3" data-testid="membership-childlevel">
                    <Form.Label>Child training level</Form.Label>
                    <ChildLevelSelect
                        name="childLevel"
                        value={formData.childLevel}
                        onChange={handleInputChangeEvent} />
                </Form.Group>
                {formData.childLevel.length > 0 &&
                    <Col lg={12}>
                        <Form.Group className="py-1 pb-3 px-3 mb-3 bg-light" data-testid="membership-childleveldate">
                            <Form.Label aria-label="child training level date achieved">When did you complete your {formData.childLevel} child training?</Form.Label>
                            <Form.Control
                                style={{ maxWidth: '220px' }}
                                name="childLevelDate"
                                value={formData.childLevelDate}
                                onChange={handleInputChangeEvent}
                                type="date"
                                placeholder="yyyy-mm-dd" />
                        </Form.Group>
                    </Col>
                }
            </Row>
            <Form.Group className="mb-3" data-testid="membership-languages">
                <Form.Label>Languages</Form.Label>
                <Form.Text className="mx-2">(Select languages you speak in addition to English)</Form.Text>
                <LanguagesSelect
                    name="languages"
                    value={formData.languages.split(', ')}
                    onChange={(values) => handleInputChange("languages", values.join(', '))}
                />


                {/*<Form.Control*/}
                {/*    required*/}
                {/*    as="textarea"*/}
                {/*    rows={2}*/}
                {/*    name="languages"*/}
                {/*    placeholder="What languages can you speak?"*/}
                {/*    value={formData.languages}*/}
                {/*    onChange={handleInputChangeEvent} />*/}
            </Form.Group>




        </div>;
    }

    function renderLoginDetails() {
        return <div>
            <Form.Group className="mb-3" data-testid="membership-type">
                <Form.Label>Membership Type</Form.Label>
                <MembershipTypeSelect

                    name="membershipType"
                    value={formData.membershipType}
                    onChange={handleInputChangeEvent} />
                <Form.Text>If you're eligible for a Membership Type not listed here, please select Full Membership and continue, but do not make payment. You should then email membership@emdrassociation.org.uk who will provide you with further instructions for completing your registration.</Form.Text>
            </Form.Group>


            <UsernameInput
                data-testid="username"
                className="mb-3"
                required
                value={formData.username}
                onChange={handleInputChangeEvent} />

            <Form.Group className="mb-3" data-testid="user-account">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    required
                    name="password"
                    minLength={12}
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{12,}"
                    value={formData.password}
                    type="password"
                    onChange={handleInputChangeEvent} />
                {validated ?
                    <Form.Control.Feedback type="invalid">
                        Passwords must be a minimum of 12 characters, both upper and lower case letters, and 1 non alphanumeric character.
                    </Form.Control.Feedback>
                    :
                    <Form.Text color="danger">Passwords must be a minimum of 12 characters, both upper and lower case letters, and 1 non alphanumeric character.</Form.Text>
                }
            </Form.Group>

            <Form.Group className="mb-3" data-testid="user-account">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                    required
                    name="confirmPassword"
                    minLength={12}
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{12,}"
                    value={formData.confirmPassword}
                    type="password"
                    onChange={handleInputChangeEvent}
                    isInvalid={formData.confirmPassword !== formData.password}
                />
            </Form.Group>


            <Form.Group className="mb-3" data-testid="membership-declaration">

                <Alert variant="light">
                    <Form.Label><strong>Declaration</strong></Form.Label>

                    <p>I confirm that I will maintain my professional registration(s) as above while I offer treatment as an EMDR Therapist.</p>
                    <p>I confirm that I do not have a criminal record that would prejudice the interests of the people I offer treatment to.</p>
                    <p>I have not been dismissed from employment or refused membership of a professional body in a related field on the grounds of professional misconduct.</p>
                    <p>I confirm that I have adequate indemnity insurance either individually or through my employer and agree to ensure I remain so covered for as long as I continue to offer treatment as an EMDR Therapist.</p>
                    <p>I agree to abide by the EMDR Europe Code of Ethics and the EMDR Association Rules and Policies.</p>
                    <p>I agree to access regular clinical supervision and to engage in continuous professional development in EMDR.</p>

                    {nonUkResident && <>
                        <p>In order to apply as a non-UK resident, I confirm I have completed an EMDR Europe accredited training.</p>
                        <p>I meet the eligibility criteria for EMDR Europe accredited training set by the EMDR Association UK https://emdrassociation.org.uk/become-an-accredited-therapist/training/</p>
                        <p>I can confirm my core professional body allows me to maintain my registration/accreditation whilst living and working outside the UK.</p>
                        <p>I have appropriate insurance.</p>
                        <p>I can confirm that if I am working with people who are citizens of my new country, I meet the core professional body requirements of that country.</p>
                        <p>I understand I must also join the local EMDR Association if am are eligible to do so.</p>
                    </>}

                    <p><a href="https://emdrassociation.org.uk/wp-content/uploads/2024/02/EMDR-Association-UK-Code-of-Conduct.pdf" target="_blank">Read the EMDR Association UK Code of Conduct</a></p>
                    <p><a href="https://emdrassociation.org.uk/about-emdr-association-uk/policies/coalesce-terms-of-service/" target="_blank">Read our Terms of Service</a></p>

                    <Form.Check
                        className="mt-4"
                        required
                        type="checkbox"
                        label="I have read and I agree to the above declaration and the EMDR Association UK Code of Conduct"
                        name="declarationAgreed"
                        checked={formData.declarationAgreed}
                        onChange={handleCheckboxChangeEvent} />

                    <Form.Check
                        className="mt-4"
                        required
                        type="checkbox"
                        label="I have read and agree to the Terms of Service"
                        name="declarationAgreed2"
                        checked={formData.declarationAgreed2}
                        onChange={handleCheckboxChangeEvent} />
                </Alert>


            </Form.Group>


            <Form.Group className="mb-3" data-testid="membership-communication">
                <Form.Label>Communication Preferences</Form.Label>
                <span data-testid="membership-communication-news">
                    <Form.Check
                        type="checkbox"
                        label="News, events and updates"
                        name="newsOptIn"
                        checked={formData.newsOptIn}
                        onChange={handleCheckboxChangeEvent} />
                </span>

                <span data-testid="membership-communication-advertising">
                    <Form.Check
                        type="checkbox"
                        label="Commercial advertising"
                        name="advertisingOptIn"
                        checked={formData.advertisingOptIn}
                        onChange={handleCheckboxChangeEvent} />
                </span>

            </Form.Group>

        </div>;
    }

};

export default MembershipJoinForm;